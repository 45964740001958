import { FIRST_MONTH_NUMBER, LAST_MONTH_NUMBER } from '@/core/constants/Calendar.constants'

export class Calendar {
    public current_date: Date
    public previous_date: Date
    public next_date: Date
    public generated_dates: Date[]

    constructor() {
        this.current_date = new Date(Date.now())
        this.previous_date = this.getPreviousDate()
        this.next_date = this.getNextDate()

        this.generated_dates = this.generateDates()
    }

    getDaysCountInMonth(year: number, month: number): number {
        return 32 - new Date(year, month, 32).getDate()
    }

    getPreviousDate(): Date {
        const current_month = this.current_date.getMonth()

        if (current_month === FIRST_MONTH_NUMBER) {
            return new Date(this.current_date.getFullYear() - 1, LAST_MONTH_NUMBER)
        }

        return new Date(this.current_date.getFullYear(), this.current_date.getMonth() - 1)
    }

    getNextDate(): Date {
        const current_month = this.current_date.getMonth()

        if (current_month === LAST_MONTH_NUMBER) {
            return new Date(this.current_date.getFullYear() + 1, FIRST_MONTH_NUMBER)
        }

        return new Date(this.current_date.getFullYear(), this.current_date.getMonth() + 1)
    }

    getDatesInMonth(days_count: number, year: number, month: number): Date[] {
        const now_date = new Date(Date.now())
        const hours = now_date.getHours()
        const minutes = now_date.getMinutes()
        const seconds = now_date.getSeconds()

        return Array.from(
            { length: days_count },
            (_, i) => new Date(year, month, i + 1, hours, minutes, seconds)
        )
    }

    cutPreviousMonthDates(): Date[] {
        const first_day_current_date = new Date(
            this.current_date.getFullYear(),
            this.current_date.getMonth(),
            1
        )

        const cut_length = first_day_current_date.getDay()

        const year = this.previous_date.getFullYear()
        const month = this.previous_date.getMonth()
        const daysCountInMonth = this.getDaysCountInMonth(year, month)

        const previous_month_dates = this.getDatesInMonth(daysCountInMonth, year, month)
        return previous_month_dates
            .sort((a, b) => b.getDate() - a.getDate())
            .splice(0, cut_length)
            .reverse()
    }

    cutNextMonthDates(dates_in_previous_month_length: number): Date[] {
        const year = this.next_date.getFullYear()
        const month = this.next_date.getMonth()

        const days_count_in_month = this.getDaysCountInMonth(year, month)
        const dates = this.getDatesInMonth(days_count_in_month, year, month)

        const days_count_in_current_date = this.getDaysCountInMonth(
            this.current_date.getFullYear(),
            this.current_date.getMonth()
        )

        const daysCountInCurrentAndNextMonth =
            days_count_in_current_date + dates_in_previous_month_length

        const rows_count = Math.ceil(daysCountInCurrentAndNextMonth / 7)
        const expected_dates_count = 7 * rows_count
        const cut_count = expected_dates_count - daysCountInCurrentAndNextMonth

        return dates.splice(0, cut_count)
    }

    generateDates(): Date[] {
        const current_year = this.current_date.getFullYear()
        const current_month = this.current_date.getMonth()
        const current_days_count = this.getDaysCountInMonth(current_year, current_month)

        const dates_in_currrent_month = this.getDatesInMonth(
            current_days_count,
            current_year,
            current_month
        )

        const dates_in_previous_month = this.cutPreviousMonthDates()
        const dates_in_next_month = this.cutNextMonthDates(dates_in_previous_month.length)

        return dates_in_previous_month.concat(dates_in_currrent_month.concat(dates_in_next_month))
    }

    setDate(date: Date): void {
        this.current_date = date
        this.previous_date = this.getPreviousDate()
        this.next_date = this.getNextDate()
        this.generated_dates = this.generateDates()
    }

    setNextMonth(): void {
        this.setDate(this.next_date)
    }

    setPreviousMonth(): void {
        this.setDate(this.previous_date)
    }

    isDateLessThanNow(date: Date): boolean {
        const date_day = date.getDate()
        const date_month = date.getMonth()
        const date_year = date.getFullYear()

        const now = new Date(Date.now())
        const now_day = now.getDate()
        const now_month = now.getMonth()
        const now_year = now.getFullYear()

        now.setHours(0, 0, 0, 0)

        if (date_day === now_day && date_month === now_month && date_year === now_year) {
            const date_hours = date.getHours()
            if (date_hours >= 14) return true
        }

        return +date < +now
    }

    isDateMoreThanNow(date: Date): boolean {
        const now = new Date(Date.now())
        now.setHours(0, 0, 0, 0)

        return +now < +date
    }

    isDateMinusFifteen(date: Date): boolean {
        const fifteenDaysAgo = new Date(Date.now())
        fifteenDaysAgo.setDate(fifteenDaysAgo.getDate() - 16)
        fifteenDaysAgo.setHours(0, 0, 0, 0)

        return +fifteenDaysAgo < +date
    }

    isDateSelected(date: Date, dateModel: Date | null): boolean {
        if (!dateModel) return false

        if (date.getDate() === dateModel.getDate() && date.getMonth() === dateModel.getMonth())
            return true

        const model_without_time = dateModel
        model_without_time.setHours(0, 0, 0, 0)
        return +date === +model_without_time
    }

    isDateInRange(
        date: Date,
        range: { date_from: Date | null; date_to: Date | null } | null
    ): boolean {
        if (!range) return false

        const date_without_time = date
        date_without_time.setHours(0, 0, 0, 0)

        const range_without_time = range
        if (!range_without_time.date_from) return false

        range_without_time.date_from.setHours(0, 0, 0, 0)
        if (+date_without_time === +range_without_time.date_from) return true

        if (!range_without_time.date_to) return false
        range_without_time.date_to.setHours(0, 0, 0, 0)

        if (
            +date_without_time >= +range_without_time.date_from &&
            +date_without_time <= +range_without_time.date_to
        )
            return true

        return false
    }

    isDateActive(
        calendar_date: Date,
        date: Date,
        hoveredDate: Date | null,
        with_range: boolean,
        range: { date_from: Date | null; date_to: Date | null } | null
    ): boolean {
        if (!with_range) return this.isDateSelected(calendar_date, date)
        if (this.isDateInRange(calendar_date, range)) return true
        if (!range || !range.date_from || !hoveredDate) return false

        if (+calendar_date >= +range.date_from && +calendar_date <= +hoveredDate) return true
        if (+calendar_date <= +range.date_from && +calendar_date >= +hoveredDate) return true

        return false
    }

    isDateSame = (date: Date) => {
        const current_date = this.current_date.toISOString().split('T')[0]
        const chosen_date = date.toISOString().split('T')[0]

        return current_date === chosen_date
    }
}
