import { createVNode as _createVNode, resolveComponent as _resolveComponent, toDisplayString as _toDisplayString, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "SCheckbox__state" }
const _hoisted_2 = { class: "SCheckbox__label" }
const _hoisted_3 = {
  key: 0,
  class: "SCheckbox__label-additional"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_icon_checkmark = _resolveComponent("icon-checkmark")!

  return (_openBlock(), _createBlock("label", {
    class: ["SCheckbox", {
            red: _ctx.theme === 'red',
            primary: _ctx.theme === 'primary',
            green: _ctx.theme === 'green',
            blue: _ctx.theme === 'blue',
            disabled: _ctx.disabled,
        }]
  }, [
    _createVNode("input", {
      class: "SCheckbox__input",
      checked: _ctx.value,
      type: "checkbox",
      hidden: "",
      onChange: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.emitChange && _ctx.emitChange(...args)))
    }, null, 40, ["checked"]),
    _createVNode("div", _hoisted_1, [
      _createVNode(_component_icon_checkmark, { class: "state-checkmark" })
    ]),
    _createVNode("div", null, [
      _createVNode("span", _hoisted_2, _toDisplayString(_ctx.label), 1),
      (_ctx.additionalLabel)
        ? (_openBlock(), _createBlock("span", _hoisted_3, _toDisplayString(_ctx.additionalLabel), 1))
        : _createCommentVNode("", true)
    ])
  ], 2))
}