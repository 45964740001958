
import { computed, defineComponent, onMounted, PropType, reactive, Ref, ref } from 'vue'

// icons
import iVectorLeft from '@/assets/icons/VectorLeft.svg'
import iVectorRight from '@/assets/icons/VectorRight.svg'

import SCheckbox from '@/common/components/SCheckbox/index.vue'

import { SHORT_DAY_NAMES, MONTH_NAMES } from '@/core/constants/Calendar.constants'

import { Calendar } from '@/core/classes/Calendar.class'
import { OptionT } from '@/core/types/common.types'
import { ReceiversRealisation } from '@/core/realisations/Receivers.realisation'

export default defineComponent({
    props: {
        date: {
            type: Date,
            default: null,
        },
        period: {
            type: Object as PropType<OptionT<number>>,
            default: null,
        },
        withPeriods: {
            type: Boolean,
            default: false,
        },
        withRange: {
            type: Boolean,
            default: false,
        },
        range: {
            type: Object as PropType<{ date_from: Date; date_to: Date }>,
            default: null,
        },
        disableLessDate: {
            type: Boolean,
            default: true,
        },
        disableMoreDate: {
            type: Boolean,
            default: false,
        },
        disableMinusFifteen: {
            type: Boolean,
            default: false,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        canDeselect: {
            type: Boolean,
            default: false,
        },
    },
    components: {
        'icon-vector-left': iVectorLeft,
        'icon-vector-right': iVectorRight,
        SCheckbox,
    },
    emits: ['update:date', 'update:period', 'update:range', 'deselect'],
    setup(props, { emit }) {
        const sendTimePeriodElement: any = ref(null)
        const receivers = new ReceiversRealisation()

        const state = reactive({
            is_period_check: false,
            period: null as OptionT<number> | null,
        })

        const data_state = reactive({
            periods: {
                is_loading: false,
                is_failed: false,
                list: [] as OptionT<number>[],
            },
        })

        const calendar = reactive(new Calendar())
        const hoveredDate: Ref<Date | null> = ref(null)

        const emitUpdatePeriod = (period: OptionT<number> | null) => {
            state.period = period
            emit('update:period', period)
        }

        const isTodayAfternoon = computed(() => {
            const props_date = props.date ? new Date(props.date) : null
            if (props_date) props_date.setDate(props_date.getDate() + 1)

            const current_date = new Date(),
                current_hour = current_date.getHours(),
                props_date_iso = formatDate(props_date),
                current_date_iso = formatDate(current_date)

            return current_hour >= 10 && props_date_iso === current_date_iso
        })

        const setPeriod = () => {
            state.is_period_check = false
            let period = null

            if (isTodayAfternoon.value) {
                period = data_state.periods.list.find((p) => p.id === 2)
            } else {
                period = data_state.periods.list.find((p) => p.id === 3)
            }

            state.period = period !== undefined ? period : null
            emitUpdatePeriod(state.period)
        }

        const selectDate = (date: Date) => {
            sendTimePeriodElement.value?.$el.scrollIntoView({ behavior: 'smooth', block: 'center' })

            if (props.withPeriods) {
                setTimeout(setPeriod, 100)
            }

            if (props.withRange) {
                if (!props.range || !props.range.date_from) {
                    emit('update:range', { date_from: date, date_to: null })
                    return
                }

                if (props.range && props.range.date_from && !props.range.date_to) {
                    if (+date < +props.range.date_from) {
                        emit('update:range', { date_from: date, date_to: props.range.date_from })
                        return
                    }

                    emit('update:range', { date_from: props.range.date_from, date_to: date })
                    return
                }

                emit('update:range', { date_from: date, date_to: null })
                return
            }

            if (calendar.isDateLessThanNow(date) && props.disableLessDate) return
            if (calendar.isDateMoreThanNow(date) && props.disableMoreDate) return
            if (calendar.isDateMinusFifteen(date) && props.disableMinusFifteen) return

            if (props.date && props.canDeselect && calendar.isDateSame(date)) {
                emit('deselect')
            } else {
                calendar.setDate(date)
                emit('update:date', date)
            }
        }

        const onCheckboxChange = (value: boolean) => {
            if (!value) setPeriod()
        }

        const hoverDate = (date: Date) => {
            if (!props.withRange || !props.range || !props.range.date_from || props.range.date_to)
                return

            date.setHours(0, 0, 0, 0)
            hoveredDate.value = date
        }

        const unhoverDate = () => {
            if (!props.withRange || !props.range || !props.range.date_from || props.range.date_to)
                return
            hoveredDate.value = null
        }

        const formatDate = (date: Date | null) => {
            if (!date) return null

            return date.toISOString().split('T')[0]
        }

        const loadPeriods = async () => {
            if (!props.withPeriods) return

            try {
                data_state.periods.is_loading = true

                const response = await receivers.getPeriods()
                data_state.periods.list = response.data.map((option) => {
                    return {
                        id: option.id,
                        name: option.title,
                        value: option.id,
                    }
                })

                const period = data_state.periods.list.find((p) => p.id === 3)
                state.period = period || null

                data_state.periods.is_failed = false
            } catch (error) {
                console.log(error)
                data_state.periods.is_failed = true
            } finally {
                data_state.periods.is_loading = false
            }
        }

        onMounted(loadPeriods)

        return {
            state,
            data_state,
            SHORT_DAY_NAMES,
            MONTH_NAMES,
            calendar,
            selectDate,
            hoverDate,
            unhoverDate,
            hoveredDate,
            sendTimePeriodElement,
            emitUpdatePeriod,
            onCheckboxChange,
            //
            isTodayAfternoon,
        }
    },
})
