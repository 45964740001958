export const SHORT_DAY_NAMES: string[] = ['Вос', 'Пон', 'Вт', 'Ср', 'Чет', 'Пят', 'Суб']
export const DAY_NAMES: string[] = [
    'Воскресенье',
    'Понедельник',
    'Вторник',
    'Среда',
    'Четверг',
    'Пятница',
    'Суббота',
]

export const MONTH_NAMES: string[] = [
    'Январь',
    'Февраль',
    'Март',
    'Апрель',
    'Май',
    'Июнь',
    'Июль',
    'Август',
    'Сентябрь',
    'Октябрь',
    'Ноябрь',
    'Декабрь',
]

export const FIRST_MONTH_NUMBER = 0
export const LAST_MONTH_NUMBER = 11

export const PERIODS = [
    {
        id: 1,
        name: 'До обеда (8:00 - 12:00)',
        value: 1,
    },
    {
        id: 2,
        name: 'После обеда (12:00 - 18:00)',
        value: 2,
    },
]
